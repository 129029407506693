<template>
  <div class="div-container">
    <validation-observer ref="refFormObserver">
      <b-form>
        <b-row>
          <b-col cols="6">
            <!-- email -->
            <validation-provider
              #default="validationContext"
              :name="$t('users.email') +'/'+ $t('users.username')"
              rules="required"
            >
              <b-form-group
                class="text-capitalize"
                :label="$t('users.email') +'/'+ $t('users.username')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="itemData.email"
                  :state="getValidationState(validationContext)"
                  trim
                   autocomplete="off"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <!-- Password -->
            <validation-provider
              #default="validationContext"
              name="Password"
              :rules="isAdd ? 'required' : ''"
            >
              <b-form-group
                class="text-capitalize"
                :label="$t('users.password')"
                label-for="Contraseña"
              >
                <b-form-input
                  id="password"
                  v-model="itemData.password"
                  :state="getValidationState(validationContext)"
                  name="password"
                  trim
                  type="password"
                   autocomplete="new-password"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                class="text-capitalize"
                :label="$t('users.name')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="itemData.name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <!-- first_name -->
            <validation-provider
              #default="validationContext"
              :name="$t('users.first_name')"
              rules="required"
            >
              <b-form-group
                class="text-capitalize"
                :label="$t('users.first_name')"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  v-model="itemData.first_name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <!-- second_name -->
            <validation-provider
              #default="validationContext"
              :name="$t('users.second_name')"
              rules="required"
            >
              <b-form-group
                class="text-capitalize"
                :label="$t('users.second_name')"
                label-for="second_name"
              >
                <b-form-input
                  id="second_name"
                  v-model="itemData.second_name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="6">
            <!-- Number Fields -->
            <b-row>
              <b-col cols="5" style="padding-right: 0">
                <!-- country -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('country.telephone_code')"
                  rules="required"
                >
                  <b-form-group
                    class="text-capitalize"
                    :label="$t('country.telephone_code')"
                    label-for="id_country"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="itemData.id_country"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="countryOptions"
                      :clearable="false"
                      :reduce="(val) => val.value"
                      input-id="id_country"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider></b-col
              >
              <b-col cols="7" style="padding-left: 0">
                <!-- phone_number -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('users.phone_number')"
                  rules="numeric|min:10"
                >
                  <b-form-group
                    class="text-capitalize input-width" 
                    :label="$t('users.phone_number')"
                    label-for="phone_number"
                  >
                    <b-form-input
                      id="phone_number"
                      v-model="itemData.phone_number"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider></b-col
              >
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <!-- plate_number -->
            <validation-provider
              #default="validationContext"
              :name="$t('users.plate_number')"
              rules=""
            >
              <b-form-group
                class="text-capitalize"
                :label="$t('users.plate_number')"
                label-for="plate_number"
              >
                <b-form-input
                  id="plate_number"
                  v-model="itemData.plate_number"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="6">
            <!-- allowed_units_live_video_flows -->
            <validation-provider
              #default="validationContext"
              :name="$t('users.allowed_units_live_video_flows')"
              rules="required"
            >
              <b-form-group
                class="text-capitalize"
                :label="$t('users.allowed_units_live_video_flows')"
                label-for="allowed_units_live_video_flows"
              >
                <b-form-input
                  id="allowed_units_live_video_flows"
                  v-model="itemData.allowed_units_live_video_flows"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <!-- vpn_access -->
            <validation-provider name="vpn_access" rules="required">
              <b-form-checkbox
                id="vpn_access"
                v-model="itemData.vpn_access"
                name="vpn_access"
                value="1"
                unchecked-value="0"
                class="my-1 text-capitalize"
                switch
              >
                {{ $t("users.vpn_access") }}
              </b-form-checkbox>
            </validation-provider>

            <!-- video wall-->
            <validation-provider name="video_wall" rules="required">
              <b-form-checkbox
                id="video_wall"
                v-model="itemData.video_wall"
                name="video_wall"
                value="1"
                unchecked-value="0"
                class="my-1 text-capitalize"
                switch
              >
                video wall
              </b-form-checkbox>
            </validation-provider>

            <!-- chat -->
          <validation-provider name="enabled_chat" rules="required">
            <b-form-checkbox
              id="enabled_chat"
              v-model="itemData.enabled_chat"
              name="enabled_chat"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
              chat
            </b-form-checkbox>
          </validation-provider>


            <!-- active -->
            <validation-provider name="active" rules="required">
              <b-form-checkbox
                id="active"
                v-model="itemData.active"
                name="active"
                value="1"
                unchecked-value="0"
                class="my-1 text-capitalize"
                switch
              >
                {{ $t("users.active") }}
              </b-form-checkbox>
            </validation-provider>
          </b-col>

          <b-col cols="6">
            <!-- alarm history -->
            <b-form-group
              class="text-capitalize"
              :label="$t('users.alarms_history')"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="hours"
                class="my-1 text-capitalize"
                @input="changeFilter"
              >
                {{ $t("users.alarm_history_per_hours") }}
              </b-form-radio>
              <b-form-input
                id="alarm_history_per_hours"
                v-model="itemData.alarm_history_per_hours"
                trim
                v-show="selected == 'hours'"
                type="number"
              />

              <b-form-radio
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="days"
                class="my-1 text-capitalize"
                @input="changeFilter"
              >
                {{ $t("users.alarm_history_per_days") }}
              </b-form-radio>
              <b-form-input
                id="alarm_history_per_days"
                v-model="itemData.alarm_history_per_days"
                trim
                v-show="selected == 'days'"
                type="number"
              />

              <b-form-radio
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="records"
                class="my-1 text-capitalize"
                @input="changeFilter"
              >
                {{ $t("users.alarm_history_by_records") }}
              </b-form-radio>
              <b-form-input
                id="alarm_history_by_records"
                v-model="itemData.alarm_history_by_records"
                trim
                v-show="selected == 'records'"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
import { ref, computed, watch, nextTick } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import vSelect from "vue-select";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormRadio,
  },
  props: {
    propItemData: {
      type: Object,
      required: false,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
      required_if,
    };
  },

  setup(props, { emit }) {
    const isUpdatingFromProps = ref(false);
    const selected = ref("hours");
    const toast = useToast();
    const { refFormObserver, getValidationState } = formValidation();
    const itemData = ref(JSON.parse(JSON.stringify(props.propItemData)));

    const changeFilter = () => {
      itemData.value.alarm_history_per_hours = 0;
      itemData.value.alarm_history_per_days = 0;
      itemData.value.alarm_history_by_records = 0;
    };

    const validateStep = () => {
      return refFormObserver.value.validate();
    };

    const setupWatcher = (itemData, key) => {
      watch(
        () => itemData.value[key],
        (newValue, oldValue) => {
          if (newValue !== oldValue) {
            emit("update:itemData", { [key]: newValue });
          }
        }
      );
    };

    watch(
      () => props.propItemData,
      (newValue) => {
        itemData.value = { ...newValue };
      },
      { deep: true }
    );

    setupWatcher(itemData, "email");
    setupWatcher(itemData, "password");
    setupWatcher(itemData, "name");
    setupWatcher(itemData, "first_name");
    setupWatcher(itemData, "second_name");
    setupWatcher(itemData, "id_country");
    setupWatcher(itemData, "phone_number");
    setupWatcher(itemData, "plate_number");
    setupWatcher(itemData, "allowed_units_live_video_flows");
    setupWatcher(itemData, "alarm_history_per_hours");
    setupWatcher(itemData, "alarm_history_per_days");
    setupWatcher(itemData, "alarm_history_by_records");
    setupWatcher(itemData, "vpn_access");
    setupWatcher(itemData, "active");
    setupWatcher(itemData, "video_wall");
    setupWatcher(itemData, "enabled_chat");

    return {
      itemData,
      changeFilter,
      refFormObserver,
      getValidationState,
      selected,
      validateStep,
      isUpdatingFromProps,
    };
  },
};
</script>

<style scoped>
.div-container {
  max-width: 80%;
  margin: auto;
}

.input-width {
  width: 80%; /* 80% father container */
  margin: 0 auto; /* Center in the father */
  display: block; /* block to marginauto */
}
</style>