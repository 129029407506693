<template>
  <div>
    <b-container>
      <!-- Table Container Card -->
      <b-card no-body>
        <div>
          <!-- Table Top -->
          <b-row class="mb-1">
            <!-- Per Page -->
            <b-col
              cols="4"
              md="4"
              class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              "
            >
              <label class="text-capitalize">{{ $t("table.show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label class="text-capitalize">{{ $t("table.entries") }}</label>
            </b-col>

            <b-col
              cols="4"
              md="4"
              class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              "
            >
              <label>{{ $t("user_cameras.camera_type") }}</label>
              <v-select
                v-model="deviceTypeFilter"
                :options="deviceTypeOptions"
                class="w-100"
                multiple
              />
            </b-col>

            <!-- Search -->
            <b-col cols="4" md="4">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block text-capitalize"
                  :placeholder="$t('table.search') + '...'"
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('no_record_found')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #head()="data">
            <span> {{ $tc(data.label, 1) }}</span>
          </template>

          <template #head(camera_proxy_user_is_assigned)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-form-checkbox
                  :id="idSector.toString()"
                  v-model="checkSector"
                  :name="idSector.toString()"
                  value="1"
                  unchecked-value="0"
                  @change="setAllSectorCameras()"
                  :disabled="assigningFlag"
                >
                </b-form-checkbox>
              </template>
            </b-media>
          </template>

          <!-- Column: Check -->
          <template #cell(camera_proxy_user_is_assigned)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-form-checkbox
                  :id="`${data.item.name}_${data.item.camera_proxy_id}`"
                  v-model="data.item.camera_proxy_user_is_assigned"
                  :name="`${data.item.name}_${data.item.camera_proxy_id}`"
                  value="1"
                  unchecked-value="0"
                  @change="setCamera(data.item)"
                  :disabled="assigningFlag"
                >
                </b-form-checkbox>
              </template>
            </b-media>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>

              <span class="text-muted text-capitalize ml-3">{{
                $t("table.total_assigned", {
                  total_assigned: dataMeta.total_assigned,
                })
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalList"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BAvatar,
  BContainer,
  BLink,
  BTabs,
  BTab,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, computed, watch } from "@vue/composition-api";
import useList from "./useList";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { props } from "vue2-dropzone";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BContainer,
    BAvatar,
    BLink,
    BTabs,
    BTab,
    vSelect,
    BFormCheckbox,
  },
  props: {
    idUser: {
      type: Number,
      required: true,
    },
    idSector: {
      type: Number,
      required: true,
    },
    showLoading: {
      type: Function,
      required: true,
    },
    closeLoading: {
      type: Function,
      required: true,
    },
    assigningFlag: {
      type: Boolean,
      required: true,
    },
    disableEnableButtons: {
      type: Function,
      required: true,
    },
  },
  setup(props, context) {
    store.dispatch("user_cameras/getDeviceTypes");

    const { t } = useI18nUtils();

    const refListTable = ref(null);

    const toast = useToast();

    const { tableColumns } = useList();

    const checkSector = ref(false);

    const perPage = ref(10);
    const totalList = ref(0);
    const totalAssigned = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const filteredIds = ref([]);
    const deviceTypeFilter = ref(null);
    const deviceTypeOptions = computed(
      () => store.getters["user_cameras/getDeviceTypes"]
    );


    const dataMeta = computed(() => {
      const localItemsCount = refListTable.value
        ? refListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalList.value,
        total_assigned: totalAssigned.value,
      };
    });

    const fetchList = (ctx, callback) => {
      //console.log("entro a obtener la informacion");
      store
        .dispatch("user_cameras/getDataTable", {
          q: searchQuery.value,
          max: perPage.value,
          "page[n]": currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          id_sector: props.idSector,
          id_user: props.idUser,
          device_type: deviceTypeFilter.value
        })
        .then((response) => {
          //console.log(response.data);
          callback(response.data);
          totalList.value = response.meta.total;
          totalAssigned.value = response.meta.total_assigned;
          console.log(totalList.value);
          console.log(response.meta.total_assigned);
          if (
            response.meta.total_assigned == totalList.value &&
            totalList.value != 0
          ) {
            checkSector.value = 1;
          } else {
            checkSector.value = 0;
          }

          if (searchQuery.value != "" || deviceTypeFilter.value?.length) {
            store
              .dispatch("user_cameras/getDataTable", {
                q: searchQuery.value,
                max: totalList.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                id_sector: props.idSector,
                id_user: props.idUser,
                device_type: deviceTypeFilter.value
              })
              .then((response) => {
                filteredIds.value = response.data.map((e) => {
                  return e.id_camera_proxy;
                });
              });
          }
        })
        .catch((e) => {
          console.log({ e });
          toast({
            component: ToastificationContent,
            props: {
              title: "Error obteniendo la información",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const formatDateAssigned = (value) => {
      const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
      return formattedDate == "Invalid date" ? "" : formattedDate;
    };

    const refetchData = () => {
      refListTable.value.refresh();
    };

    const setCamera = (item) => {
      props.showLoading();
      props.disableEnableButtons(true);
      store
        .dispatch("user_cameras/setCameraToUser", {
          id_user: props.idUser,
          id_camera_proxy: [item.camera_proxy_id],
          assing_camera: parseInt(item.camera_proxy_user_is_assigned),
        })
        .then((response) => {
          store.dispatch("user_cameras/getAll", { id_user: props.idUser })
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          if (parseInt(item.camera_proxy_user_is_assigned) == 0) {
            totalAssigned.value--;
          } else {
            totalAssigned.value++;
          }
          if (totalAssigned.value == totalList.value && totalList.value != 0) {
            checkSector.value = 1;
          } else {
            checkSector.value = 0;
          }
        })
        .catch((e) => {
          console.log({ e });
          toast(
            {
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        })
        .finally(() => {
          props.disableEnableButtons(false);
          props.closeLoading();
        });
    };

    const setAllSectorCameras = () => {
      console.log("check sector:" + checkSector.value);

      if (searchQuery.value != "" || deviceTypeFilter.value?.length) {
        props.showLoading();
        props.disableEnableButtons(true);

        store
          .dispatch("user_cameras/setCameraToUser", {
            id_user: props.idUser,
            id_camera_proxy: filteredIds.value,
            assing_camera: parseInt(checkSector.value),
          })
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            store.dispatch("user_cameras/getAll", { id_user: props.idUser })
            refetchData();
          })
          .catch((e) => {
            console.log({ e });
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                timeout: 10000,
              }
            );
          })
          .finally(() => {
            props.disableEnableButtons(false);
            props.closeLoading();
          });
        return;
      }

      if (checkSector.value == "1") {
        props.showLoading();
        props.disableEnableButtons(true);
        store
          .dispatch("user_cameras/setAllCameraSectorToUser", {
            id_user: props.idUser,
            id_sector: props.idSector,
          })
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            store.dispatch("user_cameras/getAll", { id_user: props.idUser })
            refetchData();
          })
          .catch((e) => {
            console.log({ e });
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                timeout: 10000,
              }
            );
          })
          .finally(() => {
            props.disableEnableButtons(false);
            props.closeLoading();
          });
      } else {
        props.showLoading();
        props.disableEnableButtons(true);
        store
          .dispatch("user_cameras/deleteAllCameraSectorToUser", {
            id_user: props.idUser,
            id_sector: props.idSector,
          })
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            store.dispatch("user_cameras/getAll", { id_user: props.idUser })
            refetchData();
          })
          .catch((e) => {
            console.log({ e });
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                timeout: 10000,
              }
            );
          })
          .finally(() => {
            props.disableEnableButtons(false);
            props.closeLoading();
          });
      }
    };

    watch([currentPage, perPage, searchQuery, deviceTypeFilter], () => {
      refetchData();
    });

    return {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      totalAssigned,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      setCamera,
      checkSector,
      setAllSectorCameras,
      filteredIds,
      deviceTypeOptions,
      deviceTypeFilter,
    };
  },
};
</script>



<style lang="scss">
.nav-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  //max-width: 500px;
  overflow: auto;
}
</style>
