<template>
  <div class="wizard-step-indicator">
    <template v-for="(step, index) in numberedSteps">
      <div
        :class="{
          'wizard-step': true,
          active: index === currentStep,
          disabled: isAdd,
        }"
        @click="isAdd ? null : changeStep(index)"
        :key="'step-' + index"
        :disabled="isAdd"
      >
        <div class="step-content">
          <feather-icon :icon="stepIcons[index]" size="25" />
          <div class="step-label  text-capitalize">{{ step }}</div>
        </div>
      </div>

      <!-- Añade la flecha después de cada paso, excepto el último -->
      <div
        v-if="index < numberedSteps.length - 1"
        class="arrow"
        :key="'arrow-' + index"
      >
        <feather-icon icon="ChevronRightIcon" size="40" />
      </div>
    </template>
  </div>
</template>





<script>
export default {
  props: {
    currentStep: Number,
    numberedSteps: Array,
    isAdd: Boolean,
  },
  data() {
    return {
      stepIcons: ["UserIcon", "UsersIcon", "CheckCircleIcon", "VideoIcon"],
    };
  },
  computed: {
    arrows() {
      return Array(this.numberedSteps.length - 1).fill(null);
    },
  },
  methods: {
    changeStep(index) {
      this.$emit("step-clicked", index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../assets/scss/variables/_variables";

.wizard-step-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}

.wizard-step {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;
}

.step-label {
  margin-top: 5px;
}

.arrow {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.wizard-step.active .step-content {
  opacity: 1;
}

.active {
  color: $secondary;
}

.disabled {
  cursor: default;
}
</style>
