import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";

export default function useList($i18n) {
  const refListTable = ref(null);

  const toast = useToast();

  store.dispatch("master_tenant/getAll");
  store.dispatch("tenant/getAll");
  store.dispatch("profile/getAll");
  store.dispatch("permission/getAll");
  store.dispatch("type_alarm/getAll");
  store.dispatch("country/getAll");

  const masterTenantOptions = computed(
    () => store.getters["master_tenant/getSelectedItems"]
  );
  const masterTenantFilter = ref(null);
  const tenantOptions = computed(() => store.getters["tenant/getSelectedItems"]);
  const tenantFilter = ref(null);
  const profileOptions = computed(() => store.getters["profile/getItems"].map((profile)=> {
    return {
      ...profile,
      value: profile.id,
      label: profile.type
    }
  }));
  const profileFilter = ref(null);
  const permissionOptions = computed(() => store.getters["permission/getSelectedItems"]);
  const typeAlarmOptions = computed(() => store.getters["type_alarm/getSelectedItems"]);
  const countryOptions = computed(() => store.getters["country/getSelectedItemsCode"]);

  const onlineFilter = ref(null)

  const tableColumns = [
    { label: "actions.name", key: "actions" },
    { label: "users.title", key: "user", sortable: true },
    {
      label: "users.phone_number",
      key: "phone_number_more_telephone_code",
      sortable: true,
      thStyle: "min-width: 200px",
    },
    { label: "profiles.title", key: "profile.type", sortable: true, thStyle: "min-width: 200px", },
    { label: "users.active", key: "active", sortable: true },
    { label: "users.vpn_access", key: "vpn_access", sortable: true },
    {
      label: "users.last_login",
      key: "last_login",
      sortable: true,
      formatter: "formatDateAssigned",
    },
    {
      label: "master_tenants.title",
      key: "master_tenants",
      sortable: false,
      formatter: (value, key, item) => {
        return value
          .map((item) => {
            return item.name;
          })
          .join(", ");
      },
      thStyle: "min-width: 300px",
    },
    {
      label: "tenants.title",
      key: "tenants",
      sortable: false,
      formatter: (value, key, item) => {
        return value
          .filter((tenant) => tenant.pivot.owner == 1)
          .map((item) => {
            return item.name;
          })
          .join(", ");
      },
      thStyle: "min-width: 500px",
    },
    {
      label: "shared_tenants.title",
      key: "shared_tenants",
      sortable: false,
      formatter: (value, key, item) => {
        return value
          .map((item) => {
            return item.tenant_name;
          })
          .join(", ");
      },
      thStyle: "min-width: 300px",
    },
    {
      label: "permissions.count",
      key: "permissions",
      sortable: false,
      formatter: (value, key, item) => {
        return value.length;
      },
    },
    {
      label: "types_alarms.title",
      key: "types_alarms",
      sortable: false,
      formatter: (value, key, item) => {
        return value
          .map((item) => {
            return item.description;
          })
          .join(", ");
      },
      thStyle: "min-width: 500px",
    },
    {
      label: "users.allowed_units_live_video_flows",
      key: "allowed_units_live_video_flows",
      sortable: true,
    },
    {
      label: "users.alarm_history_per_hours",
      key: "alarm_history_per_hours",
      sortable: true,
    },
    {
      label: "users.alarm_history_per_days",
      key: "alarm_history_per_days",
      sortable: true,
    },
    {
      label: "users.alarm_history_by_records",
      key: "alarm_history_by_records",
      sortable: true,
    },
    { label: "users.plate_number", key: "plate_number", sortable: true },
    { label: "users.plate_number", key: "plate_number", sortable: true },
    { label: "users.video_wall", key: "video_wall", sortable: true },
    {
      label: "date.created_at",
      key: "created",
      sortable: true,
      formatter: "formatDateAssigned",
    },
    {
      label: "date.updated_at",
      key: "updated",
      sortable: true,
      formatter: "formatDateAssigned",
    },
  ];

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const action = ref("list");
  const buttonExport = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("user/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_master_tenant: masterTenantFilter.value,
        id_tenant: tenantFilter.value,
        id_profile: profileFilter.value,
        online: onlineFilter.value,
        action: action.value,
      })
      .then((response) => {
        if (action.value == "export") {
          action.value = "list";
        } else {
          callback(response.data);
          totalList.value = response.meta.total;
        }
      })
      .catch(err => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        buttonExport.value = false;
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate == "Invalid date" ? "" : formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch(
    [profileFilter, tenantFilter, masterTenantFilter, onlineFilter, currentPage, perPage, searchQuery],
    () => {
      refetchData();
    }
  );

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    countryOptions,
    masterTenantOptions,
    masterTenantFilter,
    tenantOptions,
    tenantFilter,
    profileOptions,
    profileFilter,
    permissionOptions,
    typeAlarmOptions,
    onlineFilter,
    refetchData,
    action,
    buttonExport,
  };
}
