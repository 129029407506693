<template>
  <div :key="idUser">
    <div v-if="loading" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; align-items: center; justify-content: center; background-color: rgba(255, 255, 255, 0.8);">
      <b-spinner v-if="loading" label="Spinning" variant="primary"></b-spinner>
    </div>
    <toastification-loading-content ref="loadingToast" />

    <b-container fluid v-if="selectedCenter">
      <!-- <b-card class="m-1 mt-2"> -->
      <b-row class="m-1">
        <!-- <b-button
                class="m-1"
                variant="outline-secondary"
                @click="getBackToUsers()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button> -->
        <b-card-title class="text-lowercase mt-2">{{ userMail }}</b-card-title>

        <b-button
          class="m-1"
          variant="secondary"
          @click="setAllCameraAllCenterToUser()"
          :disabled="assigningFlag"
        >
          <span class="text-nowrap text-capitalize">
            {{ $t("user_cameras.assign_all") }}
          </span>
        </b-button>
        <b-button
          class="m-1"
          variant="secondary"
          @click="deleteAllCameraAllCenterToUser()"
          :disabled="assigningFlag"
        >
          <span class="text-nowrap text-capitalize">
            {{ $t("user_cameras.remove_all") }}
          </span>
        </b-button>
        <b-button
          class="m-1"
          variant="secondary"
          @click="setAllCenterCamerasToUser()"
          :disabled="assigningFlag"
        >
          <span class="text-nowrap text-capitalize">
            {{ $t("user_cameras.assign_all_center") }}
          </span>
        </b-button>
        <b-button
          class="m-1"
          variant="secondary"
          @click="deleteAllCenterCamerasToUser()"
          :disabled="assigningFlag"
        >
          <span class="text-nowrap text-capitalize">
            {{ $t("user_cameras.remove_all_center") }}
          </span>
        </b-button>
      </b-row>
      <b-row cols="2" class="m-1">
        <b-col md="1">
          <b-row cols="1" v-for="center in center_and_sectors" :key="center.id">
            <b-button
              class="mb-1 mx-1"
              variant="secondary"
              @click="selectCenter(center)"
            >
              <span class="text-capitalize btn-space">
                {{ center.name }} <br />
                {{ center.total_assigned_cameras }} /
                {{ center.total_visible_cameras }}
              </span>
            </b-button>
          </b-row>
        </b-col>

        <b-col md="11">
          <!-- Table Container Card -->
          <b-card no-body class="ml-2">
            <b-tabs :key="renderCount">
              <b-tab
                :title="sector.name"
                :active="index == 0 ? true : false"
                v-for="(sector, index) in selectedCenter.sectors"
                :key="sector.id"
                lazy
              >
                <user-table
                  :idSector="sector.id"
                  :idUser="idUser"
                  :showLoading="showloading"
                  :closeLoading="closeLoading"
                  :assigningFlag="assigningFlag"
                  :disableEnableButtons="disableEnableButtons"
                />
              </b-tab>
              <!-- <b-tab title="Tab 2">
                <user-table :idSector="89" :idUser="2"/>
              </b-tab > -->
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <!-- </b-card> -->
    </b-container>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BContainer,
  BAvatar,
  BLink,
  BTabs,
  BTab,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, watch, computed } from "@vue/composition-api";
import useList from "./useList";
import UserTable from "./UserTable.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";
import store from "@/store";
import router from "@/router";
import { avatarText } from "@core/utils/filter";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    UserTable,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BAvatar,
    BContainer,
    BLink,
    BTabs,
    BTab,
    vSelect,
    ToastificationLoadingContent,
    BSpinner,
  },
  props: {
    propUserId: {
      type: Number,
      default: null,
    },
    propUserEmail: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    // if(!router.currentRoute.params.id_user){
    //   router.push({name: 'user'});
    //   return
    // }

    const loading = ref(true);

    const { t } = useI18nUtils();

    const toast = useToast();

    const { tableColumns } = useList();

    const selectedCenter = ref(null);

    const assigningFlag = ref(false);

    console.log(props);
    //console.log(router.currentRoute.params.id_user);
    //const idUser = router.currentRoute.params.id_user ? ref(parseInt(router.currentRoute.params.id_user)) : props.propUserId;
    //const userMail = route.currentRoute.params.email ? new String(router.currentRoute.params.email) : props.propUserEmail;

    const idUser = ref(props.propUserId);
    const userMail = props.propUserEmail;

    const center_and_sectors = ref([]);

    const loadingToast = ref(null);

    store
      .dispatch("user_cameras/getAll", { id_user: idUser.value })
      .then(() => {
        center_and_sectors.value = store.getters["user_cameras/getItems"];

        //console.log({ center_and_sectors });

        selectedCenter.value = { ...center_and_sectors.value[0] };

        loading.value = false;

        // console.log(
        //   "SelectedCenter:" + JSON.stringify(center_and_sectors.value[0])
        // );

        //console.log(selectedCenter.value);
      })
      .catch((e) => {
        console.log({ e });
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    watch(
      () => store.getters["user_cameras/getItems"],
      (newItems) => {
        console.log("WATCH CENTERS");
        center_and_sectors.value = newItems;
      }
    );

    const selectCenter = (center) => {
      selectedCenter.value = { ...center };
      //console.log(selectedCenter.value);
    };

    const getBackToUsers = () => {
      router.push({ name: "user" });
    };

    const setAllCenterCamerasToUser = () => {
      assigningFlag.value = true;
      loadingToast.value.show(
        "Cámaras",
        "Actualizando Cámaras...",
        "secondary"
      );

      store
        .dispatch("user_cameras/setAllCameraCenterToUser", {
          id_user: idUser.value,
          id_center: selectedCenter.value.id,
        })
        .then((result) => {
          toast({
            component: ToastificationContent,
            props: {
              title: result.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          store.dispatch("user_cameras/getAll", { id_user: idUser.value });
          renderComponent();
        })
        .catch((result) => {
          console.log({ result });
          toast(
            {
              component: ToastificationContent,
              props: {
                title: result.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        })
        .finally(() => {
          assigningFlag.value = false;
          loadingToast.value.close();
        });
    };

    const deleteAllCenterCamerasToUser = () => {
      assigningFlag.value = true;
      loadingToast.value.show(
        "Cámaras",
        "Actualizando Cámaras...",
        "secondary"
      );
      store
        .dispatch("user_cameras/deleteAllCameraCenterToUser", {
          id_user: idUser.value,
          id_center: selectedCenter.value.id,
        })
        .then((result) => {
          toast({
            component: ToastificationContent,
            props: {
              title: result.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          store.dispatch("user_cameras/getAll", { id_user: idUser.value });
          renderComponent();
        })
        .catch((result) => {
          console.log({ result });
          toast(
            {
              component: ToastificationContent,
              props: {
                title: result.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        })
        .finally(() => {
          assigningFlag.value = false;
          loadingToast.value.close();
        });
    };

    const setAllCameraAllCenterToUser = () => {
      assigningFlag.value = true;
      loadingToast.value.show(
        "Cámaras",
        "Actualizando Cámaras...",
        "secondary"
      );

      store
        .dispatch("user_cameras/setAllCameraAllCenterToUser", {
          id_user: idUser.value,
        })
        .then((result) => {
          toast({
            component: ToastificationContent,
            props: {
              title: result.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          store.dispatch("user_cameras/getAll", { id_user: idUser.value });
          renderComponent();
        })
        .catch((result) => {
          console.log({ result });
          toast(
            {
              component: ToastificationContent,
              props: {
                title: result.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        })
        .finally(() => {
          assigningFlag.value = false;
          loadingToast.value.close();
        });
    };

    const deleteAllCameraAllCenterToUser = () => {
      assigningFlag.value = true;
      loadingToast.value.show(
        "Cámaras",
        "Actualizando Cámaras...",
        "secondary"
      );

      store
        .dispatch("user_cameras/deleteAllCameraAllCenterToUser", {
          id_user: idUser.value,
        })
        .then((result) => {
          toast({
            component: ToastificationContent,
            props: {
              title: result.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          store.dispatch("user_cameras/getAll", { id_user: idUser.value });
          renderComponent();
        })
        .catch((result) => {
          console.log({ result });
          toast(
            {
              component: ToastificationContent,
              props: {
                title: result.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        })
        .finally(() => {
          assigningFlag.value = false;
          loadingToast.value.close();
        });
    };

    const renderCount = ref(0);

    const renderComponent = () => {
      renderCount.value++;
    };

    const showloading = () => {
      loadingToast.value.show(
        "Cámaras",
        "Actualizando Cámaras...",
        "secondary"
      );
    };

    const closeLoading = () => {
      loadingToast.value.close();
    };

    const disableEnableButtons = (assigningValue) => {
      assigningFlag.value = assigningValue;
    };

    return {
      tableColumns,
      idUser,
      userMail,
      center_and_sectors,
      selectedCenter,
      selectCenter,
      getBackToUsers,
      setAllCenterCamerasToUser,
      deleteAllCenterCamerasToUser,
      setAllCameraAllCenterToUser,
      deleteAllCameraAllCenterToUser,
      renderComponent,
      renderCount,
      loadingToast,
      showloading,
      closeLoading,
      assigningFlag,
      disableEnableButtons,
      loading
    };
  },
};
</script>

<style lang="scss" scoped>

.btn-space {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
</style>