<template>
  <b-modal
    id="user-wizard-modal"
    v-model="isWizardActive"
    title="User Wizard"
    hide-header
    centered
    no-close-on-esc
    no-close-on-backdrop
    :size="modalSize"
    @shown="onShown"
    @hidden="resetAll"
  >
    <WizardStepIndicator
      :currentStep="currentStep"
      :numberedSteps="numberedSteps"
      @step-clicked="handleStepClicked"
      :type-alarm-options="typeAlarmOptions"
      :is-add.sync="isAdd"
    />

    <!-- <h5 class="modal-title">{{ numberedSteps[currentStep] }}</h5> -->
    <div
      class="b-modal-body"
      :style="modalStyle"
    >
      <div
        v-if="loading"
        style="
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(255, 255, 255, 0.8);
        "
      >
        <b-spinner
          v-if="currentStep === 2 || loading"
          label="Spinning"
          variant="primary"
        ></b-spinner>
      </div>

      <GeneralInfoStep
        v-if="currentStep === 0 && !loading"
        ref="generalInfoStepRef"
        :is-add.sync="isAdd"
        :prop-item-data="itemData"
        :country-options="countryOptions"
        :current-step="currentStep"
        @update:itemData="itemData = { ...itemData, ...$event }"
      />

      <TenantStep
        v-if="currentStep === 1 && !loading"
        ref="tenantStepRef"
        :prop-item-data="itemData"
        :current-step="currentStep"
        :master-tenant-options="masterTenantOptions"
        :type-alarm-options="typeAlarmOptions"
        @update:itemData="itemData = { ...itemData, ...$event }"
      />

      <PermissionStep
        v-if="currentStep === 2 && !loading"
        ref="permissionStepRef"
        :prop-item-data="itemData"
        :profile-options="profileOptions"
        @update:itemData="itemData = { ...itemData, ...$event }"
      />

      <!-- <CamerasStep
        v-if="currentStep === 3"
        :prop-user-email="userEmail"
        :prop-user-id="userId"
      /> -->

      <Device
        v-if="currentStep === 3"
        :config="config"
        @updateStatus="updateStatus"
        :assingDevices="assingDevices"
        :is-parent-loading="parentLoading"
      />
    </div>

    <template #modal-footer>
      <div class="mr-auto">
        <button
          type="button"
          class="btn btn-danger text-capitalize"
          :disabled="loading"
          @click="closeModal"
        >
          {{ $t("actions.cancel") }}
        </button>
      </div>
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <button
            type="button"
            class="btn btn-primary mr-2 text-capitalize"
            @click="save"
            :disabled="saveDisabled || loading"
            v-if="!isAdd && currentStep !== 3"
          >
            {{ $t("actions.save_changes") }}
          </button>
          <button
            type="button"
            class="btn btn-secondary mr-2 text-capitalize"
            @click="currentStep--"
            :disabled="currentStep === 0 || loading"
          >
            {{ $t("actions.previous") }}
          </button>
          <button
            type="button"
            class="btn btn-primary text-capitalize"
            @click="nextStep"
            v-if="currentStep !== numberedSteps.length - 1"
            :disabled="currentStep === numberedSteps.length - 1 || loading"
          >
            {{ $t("actions.next") }}
          </button>
          <button
            type="button"
            class="btn btn-primary text-capitalize"
            @click="closeModal"
            v-if="currentStep === numberedSteps.length - 1"
            :disabled="loading"
          >
            {{ $t("actions.finish") }}
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BSpinner } from "bootstrap-vue";
import WizardStepIndicator from "./WizardStepIndicator.vue";
import GeneralInfoStep from "./GeneralInfoStep.vue";
import TenantStep from "./TenantStep.vue";
import PermissionStep from "./PermissionStep.vue";
import CamerasStep from "@/views/user_cameras/Index.vue";
import store from "@/store";
import {
  ref,
  computed,
  watch,
  nextTick,
  onMounted,
} from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Device from "@/components/DeviceAssignment/Index.vue";

export default {
  components: {
    BModal,
    BSpinner,
    WizardStepIndicator,
    TenantStep,
    GeneralInfoStep,
    PermissionStep,
    CamerasStep,
    Device,
  },
  props: {
    isWizardActive: {
      type: Boolean,
      required: true,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
    masterTenantOptions: {
      type: Array,
      required: true,
    },
    typeAlarmOptions: {
      type: Array,
      required: true,
    },
    profileOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit, parent }) {
    const currentStep = ref(0);
    const numberedSteps = ref([
      parent.$i18n.t("users.info"),
      parent.$i18n.tc("tenants.title", 2),
      parent.$i18n.tc("permissions.title", 2),
      parent.$i18n.tc("camera.title", 2),
    ]);
    const loading = ref(false);
    const generalInfoStepRef = ref(null);
    const tenantStepRef = ref(null);
    const permissionStepRef = ref(null);
    const userId = ref(null);
    const userEmail = ref(null);
    const saveDisabled = ref("true");
    const firstItemDataOnEdit = ref(null);
    const isInitialLoadComplete = ref(false);
    const parentLoading = ref(false);

    const blankData = {
      name: "",
      first_name: "",
      second_name: "",
      phone_number: "",
      plate_number: "",
      email: "",
      password: "",
      master_tenant: null,
      tenant: [],
      id_profile: null,
      permission: null,
      type_alarm: null,
      active: 1,
      vpn_access: 0,
      allowed_units_live_video_flows: 1000,
      alarm_history_per_hours: 0,
      alarm_history_per_days: 0,
      alarm_history_by_records: 0,
      video_wall: 0,
      enabled_chat: 0,
      id_country: "",
    };

    const toast = useToast();
    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const loadData = () => {
      return new Promise((resolve) => {
        if (!props.isAdd) {
          itemData.value = props.itemEdit;
          itemData.value.master_tenant = props.itemEdit.master_tenants.map(
            (master_tenant) => {
              return master_tenant.id;
            }
          );
          itemData.value.tenant = props.itemEdit.tenants
            .filter((tenant) => tenant.pivot.owner == 1)
            .map((tenant) => {
              return tenant.id;
            });
          itemData.value.permission = props.itemEdit.permissions.map(
            (permission) => {
              return permission.id;
            }
          );
          itemData.value.type_alarm = props.itemEdit.types_alarms.map(
            (type_alarm) => {
              return type_alarm.id;
            }
          );
          userId.value = props.itemEdit.id;
          userEmail.value = props.itemEdit.email;

          firstItemDataOnEdit.value = itemData.value;
          isInitialLoadComplete.value = true;
        } else {
          itemData.value.id_country = props.countryOptions
            ? props.countryOptions[0].value
            : 1;
        }
        setTimeout(setUpWatch, 5000);
        resolve();
      });
    };

    const setUpWatch = () => {
      watch(
        () => JSON.parse(JSON.stringify(itemData.value)),
        (newVal, oldVal) => {
          if (isInitialLoadComplete.value) {
            const original = JSON.stringify(firstItemDataOnEdit.value);
            const updated = JSON.stringify(newVal);
            if (original !== updated) {
              saveDisabled.value = false;
            } else {
              saveDisabled.value = true;
            }
          }
        },
        { deep: true }
      );
    };

    const nextStep = async () => {
      let isValid = true;
      if (
        currentStep.value === 0 &&
        generalInfoStepRef.value &&
        generalInfoStepRef.value.validateStep
      ) {
        isValid = await generalInfoStepRef.value.validateStep();
      } else if (
        currentStep.value === 1 &&
        tenantStepRef.value &&
        tenantStepRef.value.validateStep
      ) {
        isValid = await tenantStepRef.value.validateStep();
      } else if (
        currentStep.value === 2 &&
        permissionStepRef.value &&
        permissionStepRef.value.validateStep
      ) {
        isValid = await permissionStepRef.value.validateStep();
      }

      //Increments the current stpe if it is valid and if its not the permission step
      if (isValid && currentStep.value !== 2) {
        currentStep.value++;
        return;
      }

      //If the actual step is valid and its the permission step, we save or edit the user.
      if (isValid && currentStep.value === 2) {
        const result = await submit();

        loading.value = false;
        if (!result) return; //something went wrong we dont change to cameras step on new users.
        loading.value = true;
        let params = {
          user: userId.value,
          assign: 1,
          action: "justIds",
        };

        await store.dispatch("camera_proxy/getAll", params);
        loading.value = false;
        currentStep.value++;
      }
    };

    const handleStepClicked = async (step) => {
      if (step === 3) {
        //if its the camerasStep

        loading.value = true;
        let params = {
          user: userId.value,
          assign: 1,
          action: "justIds",
        };

        await store.dispatch("camera_proxy/getAll", params);
        loading.value = false;
      }

      currentStep.value = step;
    };

    const onShown = () => {
      loadData();
      numberedSteps.value = [
        parent.$i18n.t("users.info"),
        parent.$i18n.tc("tenants.title", 2),
        parent.$i18n.tc("permissions.title", 2),
        parent.$i18n.tc("camera.title", 2),
      ];
    };

    const save = async () => {
      let generalInfoStepValid = true;
      let tenantsStepValid = true;
      let permisionStepValid = true;

      if (generalInfoStepRef.value) {
        generalInfoStepValid = await generalInfoStepRef.value.validateStep();
      }

      if (tenantStepRef.value) {
        tenantsStepValid = await tenantStepRef.value.validateStep();
      }

      if (permissionStepRef.value) {
        permisionStepValid = await permissionStepRef.value.validateStep();
      }

      if (generalInfoStepValid && tenantsStepValid && permisionStepValid) {
        submit();
      }
    };

    const submit = async () => {
      let response;
      loading.value = true;

      toast({
        component: ToastificationContent,
        props: {
          title: parent.$i18n.t("users.saving_info"),
          icon: "InfoIcon",
          variant: "info",
        },
      });

      try {
        if (props.isAdd) {
          response = await store.dispatch("user/add", itemData.value);
        } else {
          const req = {
            id: itemData.value.id,
            params: {
              name: itemData.value.name,
              first_name: itemData.value.first_name,
              second_name: itemData.value.second_name,
              phone_number: itemData.value.phone_number,
              plate_number: itemData.value.plate_number,
              email: itemData.value.email,
              password: itemData.value.password,
              alarm_history_per_hours: itemData.value.alarm_history_per_hours,
              alarm_history_per_days: itemData.value.alarm_history_per_days,
              alarm_history_by_records: itemData.value.alarm_history_by_records,
              allowed_units_live_video_flows:
                itemData.value.allowed_units_live_video_flows,
              master_tenant: itemData.value.master_tenant,
              tenant: itemData.value.tenant,
              id_profile: itemData.value.id_profile,
              permission: itemData.value.permission,
              type_alarm: itemData.value.type_alarm,
              active: itemData.value.active,
              vpn_access: itemData.value.vpn_access,
              video_wall: itemData.value.video_wall,
              enabled_chat: itemData.value.enabled_chat,
              id_country: itemData.value.id_country,
            },
          };
          response = await store.dispatch("user/edit", req);
        }
        if (response.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          if (props.isAdd) {
            userId.value = response.data.id;
            userEmail.value = response.data.email;
            itemData.value.id = response.data.id;
            emit("isAdd-false");
          }
          emit("refetch-data");
          loading.value = false;
          return true;
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          loading.value = false;
          return false;
        }
      } catch (response) {
        if (response.response.status == 422) {
          let string = ``;
          Object.entries(response.response.data.data).forEach((entry) => {
            const [key, value] = entry;
            string += `${key} - ${value}<br>`;
          });
          toast(
            {
              component: ToastificationContent,
              props: {
                title: `${response.response.data.message}`,
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: string,
              },
            },
            {
              timeout: 10000,
            }
          );
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
        loading.value = false;
        return false;
      }
    };

    const closeModal = () => {
      emit("close-wizard");
    };

    const resetAll = () => {
      resetData();
      currentStep.value = 0;
    };

    //CameraAssignment
    const config = {
      //id_omniview_reseller: null,
      id_user: computed(() => userId.value),
      filters: {
        tenant: true,
        center: true,
        sector: true,
        units: false,
        online: false,
        assing: true,
      },
    };

    const assingDevices = computed(
      () => store.getters["camera_proxy/getAssignedIdsItems"]
    );

    const updateStatus = async (data) => {
      parentLoading.value = true;
      if (data.multiple) {
        //if it is multiple with the options below
        if (!data.ids) {
          //if the component send the ids that want to assign, if the component do no send the ids we get all the ids depending on the filters
          let params_get_all = {
            //todo: ver si mandar los campos faltantes para que se mande todo lo que se ve en la vista.
            q: data.searchQuery,
            // max: data.perPage,
            //"page[n]": currentPage.value,
            // sortBy: data.sortBy,
            id_unit: data.id_unit,
            id_sector: data.id_sector,
            id_center: data.id_center,
            online: data.online,
            id_tenant: data.id_tenant,
            action: "justIds",
            user: userId.value,
          };

          const response = await store.dispatch(
            "camera_proxy/getAll",
            params_get_all
          );

          data.ids = response.data.cameraProxyIds;
        }
      }

      //function to assign
      let params = {
        id_user: userId.value,
        cameraProxyIds: data.ids ? data.ids : [data.id], //if it is not multiple and its just 1 camera
        status: data.status,
      };

      store
        .dispatch("user/assignCameras", params)
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          (async () => {
            try {
              await store.dispatch("camera_proxy/getAll", {
                user: userId.value,
                action: "justIds",
                assign: 1,
              });
              parentLoading.value = false;
            } catch (error) {
              parentLoading.value = false;
              console.error("Error executing action:", error);
            }
          })();
        })
        .catch((err) => {
          parentLoading.value = false;
          console.log(err);
        });
    };

    const modalSize = computed(() => {
      return currentStep.value === 3 ? 'xl' : 'lg'; 
    });

    const modalStyle = computed(() => {
      if (currentStep.value === 3) {
        return {
          height: "525px", 
          maxWidth: "100%",
          overflowY: "auto",
        };
      } else {
        return {
          height: "600px",
          maxWidth: "100%",
          overflowY: "auto",
        };
      }
    });

    return {
      currentStep,
      numberedSteps,
      itemData,
      userId,
      userEmail,
      generalInfoStepRef,
      tenantStepRef,
      permissionStepRef,
      loadData,
      resetData,
      nextStep,
      handleStepClicked,
      submit,
      loading,
      closeModal,
      resetAll,
      config,
      assingDevices,
      updateStatus,
      save,
      saveDisabled,
      parentLoading,
      onShown,
      modalSize,
      modalStyle
    };
  },
};
</script>

<style scoped>
.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>
