<template>
  <div>
    <validation-observer ref="refFormObserver">
      <b-form>
        <!-- Master Tenant -->
        <validation-provider
          #default="validationContext"
          name="Master Tenant"
          rules="required"
        >
          <b-form-group
            class="text-capitalize"
            :label="$tc('master_tenants.title', 2)"
            label-for="id_master_tenant"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="itemData.master_tenant"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="masterTenantOptions"
              :clearable="false"
              :reduce="(val) => val.value"
              input-id="id_master_tenant"
              multiple
              :closeOnSelect="false"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Tenant -->
        <validation-provider
          #default="validationContext"
          name="Tenant"
          rules="required"
        >
          <b-form-group
            class="text-capitalize"
            :label="$tc('tenants.title', 2)"
            label-for="id_tenant"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="itemData.tenant"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tenantOptions"
              :clearable="false"
              :reduce="(val) => val.value"
              input-id="id_tenant"
              multiple
              :closeOnSelect="false"
              cacheOptions="{false}"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- id_type_alarm -->
        <validation-provider
          #default="validationContext"
          :name="$tc('types_alarms.title', 2)"
          rules="required"
        >
          <b-form-group
            class="text-capitalize"
            :label="$tc('types_alarms.title', 2)"
            label-for="id_type_alarm"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="itemData.type_alarm"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeAlarmOptions"
              :clearable="false"
              :reduce="(val) => val.value"
              input-id="id_type_alarm"
              multiple
              :closeOnSelect="false"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
import { ref, computed, watch, nextTick} from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import vSelect from "vue-select";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormRadio,
  },
  props: {
    propItemData: {
      type: Object,
      required: false,
    },
    masterTenantOptions: {
      type: Array,
      required: true,
    },
    typeAlarmOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
      required_if,
    };
  },

  setup(props, { emit }) {
    const isUpdatingFromProps = ref(false);

    const selected = ref("hours");

    const toast = useToast();

    const { refFormObserver, getValidationState } = formValidation();

    const itemData = ref(JSON.parse(JSON.stringify(props.propItemData)));

    store.dispatch("tenant/getAll");

    const tenantOptions = computed(() => {
      return store.getters["tenant/getItems"]
        .filter((tenant) => {
          return itemData.value.master_tenant
            ? itemData.value.master_tenant.includes(tenant.id_master_tenant)
            : false;
        })
        .map((tenant) => {
          return {
            ...tenant,
            value: tenant.id,
            label: tenant.name,
          };
        });
    });


    const handleMasterTenantChange = (newValue) => {
      itemData.value.master_tenant = [...newValue];
      itemData.value.master_tenant = Object.assign(
        [],
        itemData.value.master_tenant
      );
    };

    const validateStep = () => {
      return refFormObserver.value.validate();
    };

    watch(
      () => props.propItemData,
      (newValue) => {
        isUpdatingFromProps.value = true;
        itemData.value = { ...newValue };
      },
      { deep: true }
    );

    watch(
      () => itemData.value.master_tenant,
      (newVal, oldVal) => {
        if (
          !newVal ||
          !oldVal ||
          !Array.isArray(newVal) ||
          !Array.isArray(oldVal)
        ) {
          console.warn("Unexpected values for newVal or oldVal");
          return;
        }

        const removedMasterTenant = oldVal.find((id) => !newVal.includes(id));

        if (removedMasterTenant && Array.isArray(itemData.value.tenant)) {
          itemData.value.tenant = itemData.value.tenant.filter((tenantId) => {
            return tenantOptions.value.some(
              (option) => option.value === tenantId
            );
          });
        }
      },
      { deep: true }
    );

    watch(
      () => itemData.value.master_tenant,
      (newValue, oldValue) => {
        if (newValue !== oldValue && !isUpdatingFromProps.value) {
          emit("update:itemData", { master_tenant: newValue });
        }
        nextTick(() => {
          isUpdatingFromProps.value = false;
        });
      }
    );

    watch(
      () => itemData.value.tenant,
      (newValue, oldValue) => {
        if (newValue !== oldValue && !isUpdatingFromProps.value) {
          emit("update:itemData", { tenant: newValue });
        }
        nextTick(() => {
          isUpdatingFromProps.value = false;
        });
      }
    );

    watch(
      () => itemData.value.type_alarm,
      (newValue, oldValue) => {
        if (newValue !== oldValue && !isUpdatingFromProps.value) {
          emit("update:itemData", { type_alarm: newValue });
        }
        nextTick(() => {
          isUpdatingFromProps.value = false;
        });
      }
    );

    return {
      itemData,
      refFormObserver,
      getValidationState,
      selected,
      handleMasterTenantChange,
      tenantOptions,
      validateStep,
      isUpdatingFromProps
    };
  },
};
</script>

<style scoped>
</style>
