<template>
  <div class="border border-success">
    <!-- Breadcrumbs -->
    <breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />

    <!-- Filters -->
    <filters
      :master-tenant-filter.sync="masterTenantFilter"
      :master-tenant-options="masterTenantOptions"
      :tenant-filter.sync="tenantFilter"
      :tenant-options="tenantOptions"
      :profile-filter.sync="profileFilter"
      :profile-options="profileOptions"
      :online-filter.sync="onlineFilter"
    />

    <!-- Wizard -->
    <wizard
      :is-wizard-active.sync="isWizardActive"
      :item-edit.sync="itemEdit"
      :is-add.sync="isAdd"
      @close-wizard="closeWizard"
      @refetch-data="refetchData"
      @isAdd-false="isAdd = false"
      :country-options="countryOptions"
      :type-alarm-options="typeAlarmOptions"
      :master-tenant-options="masterTenantOptions"
      :profile-options="profileOptions"
    />

    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 text-capitalize"
                :placeholder="$t('table.search') + '...'"
              />

              <b-button
                variant="secondary"
                @click="openWizard"
                v-if="$can('create', 'User')"
              >
                <span class="text-nowrap text-capitalize">{{
                  $t("actions.add") + " " + $tc("users.title", 1)
                }}</span>
              </b-button>
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize ml-1"
                type="button"
                :disabled="buttonExport"
              >
                <feather-icon icon="DownloadCloudIcon" size="15" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(master_tenants)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(tenants)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(shared_tenants)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(types_alarms)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.image"
                :text="
                  avatarText(
                    data.item.name +
                      ' ' +
                      data.item.first_name +
                      ' ' +
                      data.item.second_name
                  )
                "
                badge
                :badge-variant="data.item.online ? 'success' : 'danger'"
              />
            </template>
            <b-link class="font-weight-bold d-block">
              {{
                data.item.name +
                " " +
                data.item.first_name +
                " " +
                data.item.second_name
              }}
            </b-link>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            :id="`actions-${data.item.id}`"
            tabindex="0"
            variant="white"
            class="px-1 btn-y"
          >
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </b-button>
          <b-popover
            :target="`actions-${data.item.id}`"
            triggers="focus"
            placement="bottom"
            custom-class="popover-p-0"
            variant="white"
          >
            <!-- <b-list-group class="m-0">
              <b-list-group-item
                v-if="$can('update_cameras', 'User')"
                class="border-0"
                button
                @click="editUserCameras(data.item.id, data.item.email)"
              >
                <feather-icon icon="VideoIcon" />
                <span class="align-middle ml-50 text-capitalize">
                  {{ $tc("camera.title", 2) }}
                </span>
              </b-list-group-item> -->

              <b-list-group-item
                v-if="$can('update_cameras', 'User')"
                class="border-0"
                button
                @click="editUserSharedTenant(data.item.id, data.item.email)"
              >
                <feather-icon icon="VideoIcon" />
                <span class="align-middle ml-50 text-capitalize">
                  {{ $t("users.shared_tenants") }}
                </span>
              </b-list-group-item>

              <b-list-group-item
                v-if="$can('update', 'User')"
                class="border-0"
                button
                @click="openEdit(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50 text-capitalize">
                  {{ $t("actions.edit") }}
                </span>
              </b-list-group-item>

              <b-list-group-item
                v-if="$can('delete', 'User')"
                class="border-0"
                button
                @click="confirmDelete(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50 text-capitalize">
                  {{ $t("actions.delete") }}
                </span>
              </b-list-group-item>

              <b-list-group-item
                v-if="$can('read', 'ExternalToken')"
                class="border-0"
                button
                @click="externalToken(data.item.id)"
              >
                <feather-icon icon="KeyIcon" />
                <span class="align-middle ml-50 text-capitalize">
                  {{ $tc("external_token.title", 2) }}
                </span>
              </b-list-group-item>

              <b-list-group-item
                v-if="$can('read', 'ExternalUser')"
                class="border-0"
                button
                @click="externalUser(data.item.id)"
              >
                <feather-icon icon="UsersIcon" />
                <span class="align-middle ml-50 text-capitalize">
                  {{ $tc("external_user.title", 2) }}
                </span>
              </b-list-group-item>
            </b-list-group>
          </b-popover>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BAvatar,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import Wizard from "./wizard/Wizard.vue"
import Filters from "./Filters.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import router from "@/router";
import Pagination from "@/components/Pagination.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    Wizard,
    Sidenav,
    Filters,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BAvatar,
    BLink,
    vSelect,
    Breadcrumb,
  },
  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination
    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);
    const isWizardActive = ref(false);

    const openEdit = (item) => {
      //isSidebarActive.value = true;
      itemEdit.value = item;
      isAdd.value = false;
      isWizardActive.value = true;
    };
    const { t } = useI18nUtils();

    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };

    const nameNav = ref("breadcrumbs.users.all_users");

    const trans = {
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
    };

    const toast = useToast();
    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      masterTenantOptions,
      masterTenantFilter,
      tenantOptions,
      tenantFilter,
      profileOptions,
      profileFilter,
      permissionOptions,
      typeAlarmOptions,
      countryOptions,
      action,
      buttonExport,
      onlineFilter,
    } = useList();

    const editUserCameras = function (id, email) {
      router.push({
        name: "user_cameras",
        params: { id_user: id, email: email },
      });
    };

    const editUserSharedTenant = function (id, email) {
      router.push({
        name: "user_shared_tenant",
        params: { id_user: id, email: email },
      });
    };

    const closeWizard = function () {
      isWizardActive.value = false;
      store.dispatch('camera_proxy/clearAll')
    }

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: trans["messages.delete.title"],
        text: trans["messages.delete.body"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: trans["actions.delete"],
        cancelButtonText: trans["actions.cancel"],
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("user/delete", id)
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            });
        }
      });
    };

    const externalToken = function (id) {
      router.push({ path: `/external_token/${id}` });
    };

    const externalUser = function (id) {
      router.push({ path: `/external_user/${id}` });
    };

    const exportExcel = () => {
      action.value = "export";
      buttonExport.value = true;
      fetchList();
    };

    const openAdd = () => {
      isAdd.value = true;
      isSidebarActive.value = true;
    };

    const openWizard = () => {
      isAdd.value = true;
      isWizardActive.value = true;
    };

    return {
      update,
      isWizardActive,
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      openAdd,
      confirmDelete,
      avatarText,
      editUserCameras,
      editUserSharedTenant,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      nameNav,
      addItemFilterSearch,
      addItemFilter,
      itemFilter,
      masterTenantOptions,
      masterTenantFilter,
      tenantOptions,
      tenantFilter,
      profileOptions,
      profileFilter,
      permissionOptions,
      typeAlarmOptions,
      externalToken,
      externalUser,
      countryOptions,
      exportExcel,
      buttonExport,
      onlineFilter,
      openWizard,
      closeWizard
    };
  },
};
</script>

