<template>
  <div class="treeselect-container">
    <validation-observer ref="refFormObserver">
      <!-- id_profile -->
      <validation-provider
        #default="validationContext"
        :name="$tc('profiles.title', 0)"
        rules="required"
      >
        <b-form-group
          class="text-capitalize"
          :label="$tc('profiles.title', 0)"
          label-for="id_profile"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="itemData.id_profile"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="profileOptions"
            :clearable="false"
            :reduce="(val) => val.value"
            input-id="id_profile"
          />
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </validation-observer>

    <div class="d-flex align-items-center">
      <input type="checkbox" :checked="allSelected" @change="toggleAll" />
      <span class="text-capitalize" style="margin-left:5px; margin-botton:-2px">{{
        $t("actions.select_deselect_all")
      }}</span>
    </div>

    <treeselect
      v-model="selectedPermissions"
      :value-consists-of="valueConsistsOf"
      :disabled="itemData.id_profile ? false : true"
      :multiple="true"
      :options="permissionsOptions"
      :flat="false"
      :nested="true"
      :always-open="true"
      :max-height="350"
      :limit="20"
    />
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { ref, computed, watch, nextTick } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";

export default {
  components: {
    Treeselect,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: {
    propItemData: {
      type: Object,
      required: false,
    },
    profileOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isUpdatingFromProps = ref(false);
    const { refFormObserver, getValidationState } = formValidation();

    const itemData = ref(JSON.parse(JSON.stringify(props.propItemData)));

    const selectedPermissions = itemData.value.permission
      ? ref(itemData.value.permission)
      : ref([]);

    const valueConsistsOf = ref("LEAF_PRIORITY");
    const allSelected = computed(() => {
      const allIds = permissionsOptions.value.flatMap((subject) =>
        subject.children.map((child) => child.id)
      );
      return allIds.every((id) => selectedPermissions.value.includes(id));
    });

    //We get the permissions from store
    store.dispatch("permission/getAll");
    const permissionsFromStore = computed(() => {
      return store.getters["permission/getItems"];
      //   .filter(
      //     (tenant) =>
      //       !itemData.value.shared_tenants
      //         ?.map((tenant) => tenant.id_tenant)
      //         .includes(tenant.value)
      //   );
    });

    const permissionsOptions = computed(() => {
      const subjects = new Map();
      //Add each permission depending on the subject(resource)
      permissionsFromStore.value.forEach((permission) => {
        if (!subjects.has(permission.subject)) {
          subjects.set(permission.subject, {
            id: permission.subject,
            label: permission.subject,
            children: [],
          });
        }
        const subject = subjects.get(permission.subject);
        subject.children.push({
          id: permission.id,
          label: permission.description,
        });
      });

      //return it like a array so the library can use it
      return Array.from(subjects.values());
    });

    const toggleAll = () => {
      if (allSelected.value) {
        selectedPermissions.value = [];
      } else {
        selectedPermissions.value = permissionsOptions.value.flatMap(
          (subject) => subject.children.map((child) => child.id)
        );
      }
    };

    const validateStep = () => {
      return refFormObserver.value.validate();
    };

    watch(
      () => itemData.value.id_profile,
      (newValue, oldValue) => {
        if (newValue !== oldValue && !isUpdatingFromProps.value) {
          //finds the object of profile in profileOptions based on the newValue
          const profileObj = props.profileOptions.find(
            (profile) => profile.id === newValue
          );

          if (profileObj && profileObj.permissions) {
            //Extracts the ids of the permissions from the profile found
            selectedPermissions.value = profileObj.permissions.map(
              (perm) => perm.id
            );
          }
        }
      }
    );

    watch(
      () => props.propItemData,
      (newValue) => {
        isUpdatingFromProps.value = true;
        selectedPermissions.value = [...newValue.permission];
      },
      { deep: true }
    );

    watch(
      () => selectedPermissions.value,
      (newValue, oldValue) => {
        //this is to validate when the update comes from props.
        if (newValue !== oldValue && !isUpdatingFromProps.value) {
          emit("update:itemData", { permission: newValue });
        }
        nextTick(() => {
          isUpdatingFromProps.value = false;
        });
      }
    );

    watch(
      () => itemData.value.id_profile,
      (newValue, oldValue) => {
        if (newValue !== oldValue && !isUpdatingFromProps.value) {
          emit("update:itemData", { id_profile: newValue });
        }
      }
    );

    return {
      refFormObserver,
      getValidationState,
      itemData,
      selectedPermissions,
      permissionsOptions,
      valueConsistsOf,
      allSelected,
      toggleAll,
      validateStep,
    };
  },
};
</script>

<style scoped>
.treeselect-container {
  height: 100% !important;
}
</style>

