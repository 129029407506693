<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50">
      <h5 class="text-capitalize">
        {{ $tc('actions.filter', 2) }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="3"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $tc('master_tenants.title', 1) }}</label>
          <v-select
            data-cy="automatest-v-select-master_tenants"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="masterTenantFilter"
            :options="masterTenantOptions"

            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:masterTenantFilter', val)"
          />
        </b-col>
        <b-col
          cols="3"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $tc('tenants.title', 1) }}</label>
          <v-select
            data-cy="automatest-v-select-tenants"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="tenantFilter"
            :options="tenantOptions"

            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:tenantFilter', val)"
          />
        </b-col>
        <b-col
          cols="3"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $tc('profiles.title', 0) }}</label>
          <v-select
            data-cy="automatest-v-select-profile"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="profileFilter"
            :options="profileOptions"

            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:profileFilter', val)"
          />
        </b-col>
        <b-col
          cols="3"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $t('camera.online') }}</label>
          <v-select
            data-cy="automatest-v-select-online"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="onlineFilter"
            class="w-100"
            :reduce="val => val.value"
            :options="[{label: 'online', value: true}, {label: 'offline', value: false}]"
            @input="(val) => $emit('update:onlineFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    masterTenantOptions: {
      type: Array,
      default: null,
    },
    masterTenantFilter: {
      type: Array,
      default: null,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    profileOptions: {
      type: Array,
      default: null,
    },
    profileFilter: {
      type: Array,
      default: null,
    },
    onlineFilter: {
      type: Boolean,
      default: false,
    },
  },
}
</script>