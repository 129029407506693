<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{
            (isAdd ? $t("actions.add") : $t("actions.edit")) +
            " " +
            $tc("users.title", 1)
          }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- email -->
          <validation-provider
            #default="validationContext"
            name="email"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('users.email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="itemData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Password"
            :rules="isAdd ? 'required' : ''"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('users.password')"
              label-for="Contraseña"
            >
              <b-form-input
                id="password"
                v-model="itemData.password"
                :state="getValidationState(validationContext)"
                name="password"
                trim
                type="password"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('users.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- first_name -->
          <validation-provider
            #default="validationContext"
            name="first_name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('users.first_name')"
              label-for="first_name"
            >
              <b-form-input
                id="first_name"
                v-model="itemData.first_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- second_name -->
          <validation-provider
            #default="validationContext"
            name="second_name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('users.second_name')"
              label-for="second_name"
            >
              <b-form-input
                id="second_name"
                v-model="itemData.second_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-row>
            <b-col cols="4" style="padding-right: 0">
              <!-- country -->
              <validation-provider
                #default="validationContext"
                name="id_country"
                rules="required"
              >
                <b-form-group
                  class="text-capitalize"
                  :label="$t('country.telephone_code')"
                  label-for="id_country"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="itemData.id_country"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countryOptions"
                    :clearable="false"
                    :reduce="(val) => val.value"
                    input-id="id_country"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider></b-col
            >
            <b-col cols="8" style="padding-left: 0">
              <!-- phone_number -->
              <validation-provider
                #default="validationContext"
                name="phone_number"
              >
                <b-form-group
                  class="text-capitalize"
                  :label="$t('users.phone_number')"
                  label-for="phone_number"
                >
                  <b-form-input
                    id="phone_number"
                    v-model="itemData.phone_number"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider></b-col
            >
          </b-row>

          <!-- plate_number -->
          <validation-provider
            #default="validationContext"
            name="plate_number"
            rules=""
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('users.plate_number')"
              label-for="plate_number"
            >
              <b-form-input
                id="plate_number"
                v-model="itemData.plate_number"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- allowed_units_live_video_flows -->
          <validation-provider
            #default="validationContext"
            name="allowed_units_live_video_flows"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('users.allowed_units_live_video_flows')"
              label-for="allowed_units_live_video_flows"
            >
              <b-form-input
                id="allowed_units_live_video_flows"
                v-model="itemData.allowed_units_live_video_flows"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- id_profile -->
          <validation-provider
            #default="validationContext"
            name="id_profile"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('profiles.title', 0)"
              label-for="id_profile"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_profile"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="profileOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_profile"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- id_permission -->
          <validation-provider
            #default="validationContext"
            name="id_permission"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('permissions.title', 2)"
              label-for="id_permission"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.permission"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="permissionOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_permission"
                multiple
                :closeOnSelect="false"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Master Tenant -->
          <validation-provider
            #default="validationContext"
            name="Master Tenant"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('master_tenants.title', 2)"
              label-for="id_master_tenant"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.master_tenant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="masterTenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_master_tenant"
                multiple
                :closeOnSelect="false"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tenant -->
          <validation-provider
            #default="validationContext"
            name="Tenant"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('tenants.title', 2)"
              label-for="id_tenant"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.tenant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_tenant"
                multiple
                :closeOnSelect="false"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- id_type_alarm -->
          <validation-provider
            #default="validationContext"
            name="id_type_alarm"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('types_alarms.title', 2)"
              label-for="id_type_alarm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.type_alarm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeAlarmOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_type_alarm"
                multiple
                :closeOnSelect="false"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            class="text-capitalize"
            :label="$t('users.alarms_history')"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="hours"
              class="my-1 text-capitalize"
              @input="changeFilter"
            >
              {{ $t("users.alarm_history_per_hours") }}
            </b-form-radio>
            <b-form-input
              id="alarm_history_per_hours"
              v-model="itemData.alarm_history_per_hours"
              trim
              v-show="selected == 'hours'"
              type="number"
            />

            <b-form-radio
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="days"
              class="my-1 text-capitalize"
              @input="changeFilter"
            >
              {{ $t("users.alarm_history_per_days") }}
            </b-form-radio>
            <b-form-input
              id="alarm_history_per_days"
              v-model="itemData.alarm_history_per_days"
              trim
              v-show="selected == 'days'"
              type="number"
            />

            <b-form-radio
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="records"
              class="my-1 text-capitalize"
              @input="changeFilter"
            >
              {{ $t("users.alarm_history_by_records") }}
            </b-form-radio>
            <b-form-input
              id="alarm_history_by_records"
              v-model="itemData.alarm_history_by_records"
              trim
              v-show="selected == 'records'"
              type="number"
            />
          </b-form-group>

          <!-- vpn_access -->
          <validation-provider name="vpn_access" rules="required">
            <b-form-checkbox
              id="vpn_access"
              v-model="itemData.vpn_access"
              name="vpn_access"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
              {{ $t("users.vpn_access") }}
            </b-form-checkbox>
          </validation-provider>

          <!-- active -->
          <validation-provider name="active" rules="required">
            <b-form-checkbox
              id="active"
              v-model="itemData.active"
              name="active"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
              {{ $t("users.active") }}
            </b-form-checkbox>
          </validation-provider>

          <!-- video wall-->
          <validation-provider name="video_wall" rules="required">
            <b-form-checkbox
              id="video_wall"
              v-model="itemData.video_wall"
              name="video_wall"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
              video wall
            </b-form-checkbox>
          </validation-provider>

          <!-- video wall-->
          <validation-provider name="enabled_chat" rules="required">
            <b-form-checkbox
              id="enabled_chat"
              v-model="itemData.enabled_chat"
              name="enabled_chat"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
              enabled chat
            </b-form-checkbox>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              data-cy="send-form"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") : $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="text-capitalize"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref, computed } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    masterTenantOptions: {
      type: Array,
      required: true,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
    permissionOptions: {
      type: Array,
      required: true,
    },
    profileOptions: {
      type: Array,
      required: true,
    },
    typeAlarmOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const blankData = {
      name: "",
      first_name: "",
      second_name: "",
      phone_number: "",
      plate_number: "",
      email: "",
      password: "",
      master_tenant: null,
      tenant: [],
      id_profile: null,
      permission: null,
      type_alarm: null,
      active: 1,
      vpn_access: 0,
      allowed_units_live_video_flows: 1000,
      alarm_history_per_hours: 0,
      alarm_history_per_days: 0,
      alarm_history_by_records: 0,
      video_wall: 0,
      id_country: "",
      enabled_chat: 0,
    };

    const selected = ref("hours");

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    store.dispatch("tenant/getAll");

    const tenantOptions = computed(() => {
      return store.getters["tenant/getSelectedItems"].filter(
        (tenant) =>
          !itemData.value.shared_tenants
            ?.map((tenant) => tenant.id_tenant)
            .includes(tenant.value)
      );
    });

    const changeFilter = () => {
      itemData.value.alarm_history_per_hours = 0;
      itemData.value.alarm_history_per_days = 0;
      itemData.value.alarm_history_by_records = 0;
    };

    const onSubmit = () => {
      let dispatch;

      if (props.isAdd) {
        dispatch = store.dispatch("user/add", itemData.value);
      } else {
        selected.value =
          itemData.value.alarm_history_per_hours > 0
            ? "hours"
            : itemData.value.alarm_history_per_days
            ? "days"
            : "records";

        const req = {
          id: itemData.value.id,
          params: {
            name: itemData.value.name,
            first_name: itemData.value.first_name,
            second_name: itemData.value.second_name,
            phone_number: itemData.value.phone_number,
            plate_number: itemData.value.plate_number,
            email: itemData.value.email,
            password: itemData.value.password,
            alarm_history_per_hours: itemData.value.alarm_history_per_hours,
            alarm_history_per_days: itemData.value.alarm_history_per_days,
            alarm_history_by_records: itemData.value.alarm_history_by_records,
            allowed_units_live_video_flows:
              itemData.value.allowed_units_live_video_flows,
            master_tenant: itemData.value.master_tenant,
            tenant: itemData.value.tenant,
            id_profile: itemData.value.id_profile,
            permission: itemData.value.permission,
            type_alarm: itemData.value.type_alarm,
            active: itemData.value.active,
            vpn_access: itemData.value.vpn_access,
            video_wall: itemData.value.video_wall,
            enabled_chat: itemData.value.enabled_chat,
            id_country: itemData.value.id_country,
          },
        };

        dispatch = store.dispatch("user/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          if (response.response.status == 422) {
            let string = ``
            Object.entries(response.response.data.data).forEach(entry => {
              const [key, value] = entry
              string += `${key} - ${value}<br>`
            })
            toast({
              component: ToastificationContent,
              props: {
                title: `${response.response.data.message}`,
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: string
              },
            },{
              timeout: 10000
            }
            )
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
        itemData.value.master_tenant = props.itemEdit.master_tenants.map(
          (master_tenant) => {
            return master_tenant.id;
          }
        );
        itemData.value.tenant = props.itemEdit.tenants
          .filter((tenant) => tenant.pivot.owner == 1)
          .map((tenant) => {
            return tenant.id;
          });
        itemData.value.permission = props.itemEdit.permissions.map(
          (permission) => {
            return permission.id;
          }
        );
        itemData.value.type_alarm = props.itemEdit.types_alarms.map(
          (type_alarm) => {
            return type_alarm.id;
          }
        );
      }
    };

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      selected,
      changeFilter,
      tenantOptions,
    };
  },
};
</script>
