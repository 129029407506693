import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'

export default function useList($i18n) {


  const tableColumns = [
    { label:  'user_cameras.camera_proxy_user_is_assigned', key: 'camera_proxy_user_is_assigned', sortable: false },
    { label:  'id', key: 'id', sortable: false },
    { label: 'user_cameras.name', key: 'name', sortable: false },
    { label: 'user_cameras.unit', key: 'units', sortable: false },
    // { label: 'user_cameras.camera_type', key: 'camera_type', sortable: false },
    { label: 'user_cameras.cameraID', key: 'camera_proxy_id', sortable: false },
    // { label: 'user_cameras.id_user', key: 'userId', sortable: false },
    // { label: 'user_cameras.id_account', key: 'userId', sortable: false },
  ]

  return {
    tableColumns,
  }
}
